import React from 'react';
import { graphql } from 'gatsby';
import { css } from '@emotion/core';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { PageLink } from '../components/layout/style';
import { colors } from '../constants';

class Explanations extends React.Component {
  render() {
    const siteTitle = this.props.data.site.siteMetadata.title;
    const explanations = this.props.data.explanations.edges;
    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title="Mygo's explanations"
          keywords={[
            `blog`,
            `gatsby`,
            `javascript`,
            `react`,
            `mygo explains`,
            `explanations`,
          ]}
        />
        <h2
          css={css`
            text-align: center;
            display: block;
          `}
        >
          Explanations
        </h2>
        <p>This is a list of all explanations I've written thus far. </p>
        <p>
          The explanations might be too clinical. My personal opinion is that
          the explanations lack personal flavor and they need to be weaved into
          personal stories. Since I don't want to delete the ones I've written,
          I've decided to preserve them here and then figure out a way to
          incorporate them into later content (as of: 29 April 2019).
        </p>
        {explanations.map(({ node }) => {
          return (
            <div key={node.fields.slug}>
              <PageLink
                to={node.fields.slug}
                css={css`
                  color: ${colors.link.normal};
                `}
              >
                {node.frontmatter.title}
              </PageLink>
              <br />
            </div>
          );
        })}
      </Layout>
    );
  }
}

export default Explanations;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    explanations: allMarkdownRemark(
      filter: { fields: { type: { eq: "explanations" } } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
            type
          }
          frontmatter {
            date
            title
          }
        }
      }
    }
  }
`;
